

export const STATUS = {
  Active: 1,
  Available: 2,
  Disabled: 3,
  Deleted: 4,
  OutOfStock: 5,
  Selected: 6,
  Confirmed: 7,
  Completed: 8,
  Accepted: 9,
  Rejected: 10,
  Cancelled: 11,
  Archived: 12,
  Closed: 13,
  Requested: 14,
  Enabled: 15,
  Hidden: 16,
};
