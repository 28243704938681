import payments from "../assets/images/payments.png";
import { Rate } from "antd";
import React, { useEffect, useState } from "react";
import { GetAllInfoApi } from "../api/GetAllInfoApi";
import MainLoader from "../components/Loader/MainLoader";
import { useLocation } from "react-router-dom";

function Profile() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const curLocation = useLocation();

  const path = curLocation.pathname;
  const id = path.split("/");

  useEffect(() => {
    getAllInfo();
  }, []);

  async function getAllInfo() {
    setLoading(true);
    const apiResponse = await GetAllInfoApi(
      id[1],
      localStorage.getItem("userId")
    );

    if (apiResponse.success) {
      setData(apiResponse.data);
      setLoading(false);
    }
  }

  return (
    <>
      {!loading ? (
        <>
          {data?.coverPhoto ? (
            <img
              className="h-32 w-full object-cover"
              src={data?.coverPhoto}
              alt="cover-img"
            />
          ) : (
            <div className="flex justify-center items-center h-32 w-96 bg-gray-200"></div>
          )}
          {data?.profile ? (
            <img
              className="w-32 h-32 bg-gary-200 rounded-full  mx-auto -mt-12 border-4 border-white"
              src={data?.profile}
              alt="profile-img"
            />
          ) : (
            <div className="w-32 h-32 bg-gray-200  rounded-full flex justify-center items-center mx-auto -mt-12 border-4 border-white"></div>
          )}
          <div className="container">
            <div className="text-center px-4">
              <div className="text-lg">{data?.name}</div>
              <div className="text-sm text-gray-500">{data?.description}</div>
            </div>

            <div className="mt-3 text-center text-sm">Rating</div>
            <div className="flex-center px-4">
              <Rate allowHalf defaultValue={4.5} />
            </div>

            <div className="mt-3 text-center text-sm">Address</div>
            <div className="text-center text-sm  text-gray-500">
              {data?.address}
            </div>

            <div className="mt-3 text-center text-sm">Phone</div>
            <div className="text-center text-sm text-gray-500">
              {data?.phoneNumber}
            </div>

            <div className="mt-3 text-center text-sm">Payment methods</div>
            <div className="flex-center mb-8">
              <img src={payments} className="image" alt="Payment Methods" />
            </div>
          </div>
        </>
      ) : (
        <MainLoader />
      )}
    </>
  );
}

export default Profile;
