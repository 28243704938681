import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GetOrderItemApi } from "../api/GetOrderItemApi";
import ConfirmationModal from "../components/Modals/ConfirmationModal";

import {
  CheckCircleTwoTone,
  ClockCircleOutlined,
  ClockCircleTwoTone,
  ExclamationCircleTwoTone,
  PlusCircleTwoTone,
  WarningOutlined,
} from "@ant-design/icons";
import AcceptOrderItemsInfoModal from "../components/Modals/AcceptOrderItemsInfoModal";
import ConfirmOrderItemsInfoModal from "../components/Modals/ConfirmOrderItemsInfoModal";
import RejectOrderItemsInfoModal from "../components/Modals/RejectOrderItemsInfoModal";
import SelectedOrderItemsInfoModal from "../components/Modals/SelectedOrderItemsInfoModal";
import MainLoader from "../components/Loader/MainLoader";
import { useSocket } from "../providers/SocketProvider";
import { currencyFormatter } from "../Helpers/currencyFormat";
import { GetAllInfoApi } from "../api/GetAllInfoApi";
import { STATUS } from "../Helpers/constants";

function Cart2(props) {
  const [resetCart, setResetCart] = useState(false);
  const [confirmOrderModal, setConfirmOrderModal] = useState(false);
  const [completeOrderModal, setCompleteOrderModal] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [confirmItemModal, setConfirmItemModal] = useState(false);
  const [confirmItem, setConfirmItem] = useState();
  const [acceptItemModal, setAcceptItemModal] = useState(false);
  const [acceptItem, setAcceptItem] = useState();
  const [rejectItemModal, setRejectItemModal] = useState(false);
  const [rejectItem, setRejectItem] = useState();
  const [selectItemModal, setSelectItemModal] = useState(false);
  const [selectItem, setSelectItem] = useState();
  const [disabled, setDisabled] = useState(false);
  const [requested, setRequested] = useState(false);
  const [orderRequested, setOrderRequested] = useState(false);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [orderRejected, setOrderRejected] = useState(false);
  const [business, setBusiness] = useState([]);
  const [orderNotFound, setOrderNotFound] = useState(false);
  const [billRequested, setBillRequested] = useState(false);
  const curLocation = useLocation();

  const path = curLocation.pathname;
  const id = path.split("/");
  const socket = useSocket();
  let navigate = useNavigate();

  useEffect(() => {
    getAllInfo();
  }, []);

  async function getAllInfo() {
    const apiResponse = await GetAllInfoApi(
      id[1],
      localStorage.getItem("userId")
    );

    if (apiResponse.success) {
      setBusiness(apiResponse.data);
    }
  }

  useEffect(() => {
    setData(props.data?.data);
    getOrder();
  }, [
    props.data,
    props.confirmData,
    props.acceptData,
    props.rejectData,
    props.cancelOrderData,
    props.getRealtimeData,
    props.enableCustomerAdmin,
    props.disableCustomerAdmin,
    props.enableCustomerByAnother,
    props.disableCustomerByAnother,
  ]);

  const sendResetOrder = () => {
    socket?.emit("resetOrder", resetOrderDetail);
  };

  const sendConfirmOrder = () => {
    socket?.emit("confirmOrder", confirmOrderDetail);
  };

  const sendCompleteOrder = async () => {
    try {
      await socket?.emit("completeOrder", completeOrderDetail);
      setCompleteOrderModal(false);
      setBillRequested(true);
    } catch (error) {
      console.error("Error completing order:", error);
    }
  };

  useEffect(() => {
    if (props.closeData) {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/invoice`);
    }
  }, [props.closeData]);

  const resetOrderDetail = {
    orderId: id[3],
  };

  const confirmOrderDetail = {
    orderId: id[3],
  };

  const completeOrderDetail = {
    id: id[3],
  };

  const resetOrder = (data) => {
    props.handleData(data?.data);
  };

  const confirmOrder = (data) => {
    props.handleData(data?.data);
  };

  const handleData = (newData) => {
    props.handleData(newData);
  };

  useEffect(() => {
    socket?.on("resetOrder", resetOrder);
    socket?.on("confirmOrder", confirmOrder);
    socket?.on("completeOrder");

    return () => {
      socket?.off("resetOrder", resetOrder);
      socket?.off("confirmOrder", confirmOrder);
      socket?.off("completeOrder");
    };
  }, [socket]);

  useEffect(() => {
    socket?.on("removeOrderItem", () => {
      setData(props.data?.data);
      getOrder();
    });

    return () => {
      socket?.off("removeOrderItem");
    };
  }, [socket]);

  async function getOrder() {
    setLoading(true);
    setRequested(false);
    setDisabled(false);
    setOrderRequested(false);
    setOrderCancelled(false);
    setOrderRejected(false);
    setOrderNotFound(false);

    const apiResponse = await GetOrderItemApi(
      id[3],
      localStorage.getItem("userId")
    );

    if (apiResponse.message === "you are in requested status") {
      setRequested(true);
    } else if (apiResponse.message === "Not Acceptable") {
      setDisabled(true);
    } else if (apiResponse.message === "Requested in order") {
      setOrderRequested(true);
    } else if (apiResponse.message === "your order is cancelled") {
      setOrderCancelled(true);
    } else if (apiResponse.message === "your order is rejected") {
      setOrderRejected(true);
    } else if (
      apiResponse.success &&
      apiResponse.data?.adminDetail?.orderStatus === STATUS.Closed
    ) {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/invoice`);
    } else if (
      apiResponse.success &&
      apiResponse.data?.adminDetail?.orderStatus === STATUS.Active
    ) {
      setData(apiResponse.data);
    } else {
      setOrderNotFound(true);
    }
    setLoading(false);
  }

  function renderConfirmedOrderItems() {
    let output = [];

    data?.customerConfirmedOrderDetail?.orderDetails?.orderItemConfirmed.forEach(
      (item) => {
        output.push(
          <div
            key={item.id}
            className="flex mb-2  "
            onClick={() => {
              setConfirmItemModal(true);
              setConfirmItem(item);
            }}
          >
            <div className=" flex w-8/12 gap-2">
              <div className=" flex justify-center mt-0.5">
                <ClockCircleTwoTone
                  twoToneColor="#bae637"
                  className="text-base"
                  style={{ fontSize: "20px" }}
                />
              </div>
              <div>
                <div className="font-bold">{item?.menuName}</div>
                <div>
                  {item?.orderBy?.map((value, index) => (
                    <span
                      key={index}
                      className="mr-1 text-sm md:text-base text-gray-500"
                    >
                      {value.name}
                      {index !== item.orderBy.length - 1 && ","}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-1/12 text-sm md:text-base">{item?.quantity}</div>
            <div className="w-3/12 text-right text-sm md:text-base">
              {currencyFormatter(item?.price, business?.currency)}
            </div>
          </div>
        );
      }
    );
    return output;
  }

  function renderAcceptedOrderItems() {
    let output = [];

    data?.customerConfirmedOrderDetail?.orderDetails?.orderItemAccepted.forEach(
      (item) => {
        output.push(
          <div
            key={item.id}
            className="flex mb-2  "
            onClick={() => {
              setAcceptItemModal(true);
              setAcceptItem(item);
            }}
          >
            <div className=" flex w-8/12 gap-2">
              <div className=" flex justify-center mt-0.5">
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{ fontSize: "20px" }}
                />
              </div>
              <div>
                <div className="font-bold">{item?.menuName}</div>
                <div>
                  {item?.orderBy?.map((value, index) => (
                    <span
                      key={index}
                      className="mr-1 text-sm md:text-base text-gray-500"
                    >
                      {value.name}
                      {index !== item.orderBy.length - 1 && ","}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-1/12 text-sm md:text-base">{item?.quantity}</div>
            <div className="w-3/12 text-right text-sm md:text-base">
              {currencyFormatter(item?.price, business?.currency)}
            </div>
          </div>
        );
      }
    );
    return output;
  }

  function renderRejectedOrderItems() {
    let output = [];

    data?.customerConfirmedOrderDetail?.orderDetails?.orderItemRejected.forEach(
      (item) => {
        output.push(
          <div
            key={item.id}
            className="flex mb-2  "
            onClick={() => {
              setRejectItemModal(true);
              setRejectItem(item);
            }}
          >
            <div className=" flex w-8/12 gap-2">
              <div className=" flex justify-center mt-0.5">
                <ExclamationCircleTwoTone
                  twoToneColor="#ff7875"
                  style={{ fontSize: "20px" }}
                />
              </div>
              <div>
                <div className="font-bold">{item?.menuName}</div>
                <div>
                  {item?.orderBy?.map((value, index) => (
                    <span
                      key={index}
                      className="mr-1 text-sm md:text-base text-gray-500"
                    >
                      {value.name}
                      {index !== item.orderBy.length - 1 && ","}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-1/12 text-sm md:text-base">{item?.quantity}</div>
            <div className="w-3/12 text-right text-sm md:text-base">
              {currencyFormatter(item?.price, business?.currency)}
            </div>
          </div>
        );
      }
    );
    return output;
  }

  function renderSelectedOrderItems() {
    let output = [];

    data?.customerSelectedOrderDetail?.orderDetails?.orderSelectedCartDetail.forEach(
      (item) => {
        output.push(
          <div
            key={item.id}
            className="flex mb-2 cursor-pointer"
            onClick={() => {
              setSelectItemModal(true);
              setSelectItem(item);
            }}
          >
            <div className="flex gap-2 w-8/12">
              <div className=" flex justify-center mt-0.5">
                <PlusCircleTwoTone style={{ fontSize: "20px" }} />
              </div>
              <div>
                <div className="font-bold">{item?.menuName}</div>
                <div>
                  {item?.orderBy?.map((value, index) => (
                    <span
                      key={index}
                      className="mr-1 text-sm md:text-base text-gray-500"
                    >
                      {value.name}
                      {index !== item.orderBy.length - 1 && ","}
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-1/12 text-sm md:text-base">{item?.quantity}</div>
            <div className="w-3/12 text-right text-sm md:text-base">
              {currencyFormatter(item?.price, business?.currency)}
            </div>
          </div>
        );
      }
    );

    return output;
  }

  const anySectionRendered =
    data &&
    (renderConfirmedOrderItems().length > 0 ||
      renderAcceptedOrderItems().length > 0 ||
      renderRejectedOrderItems().length > 0 ||
      renderSelectedOrderItems().length > 0);

  const renderNoSelectedItemsMessage = () => {
    if (
      !data?.customerConfirmedOrderDetail?.orderDetails?.orderItemAccepted
        .length &&
      !data?.customerConfirmedOrderDetail?.orderDetails?.orderItemRejected
        .length &&
      !data?.customerConfirmedOrderDetail?.orderDetails?.orderItemConfirmed
        .length &&
      !renderSelectedOrderItems().length
    ) {
      return (
        <div className="text-center mt-10 text-gray-400">
          Please add items to your order
          <Link to={`/${id[1]}/${id[2]}/${id[3]}/menu`}>
            <div className="p-2 bg-black text-white text-center border rounded-lg shadow-sm mt-4">
              <div>Add Items </div>
            </div>
          </Link>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {!loading ? (
        orderNotFound ? (
          <div className="flex flex-col items-center justify-center">
            <div className="flex w-full mt-36 items-center justify-center">
              <div className="grid gap-y-2">
                <WarningOutlined
                  className="text-gray-400"
                  style={{ fontSize: "50px" }}
                />
                <div className="text-gray-400">Order not found</div>
              </div>
            </div>
            <div className="mt-10">
              <a
                href="https://dinegrid.com/"
                className="p-3 px-6 rounded-lg shadow-lg text-gray-500 hover:text-blue-500 border border-gray-500 hover:border-blue-500"
                style={{ textDecoration: "none" }}
              >
                Dinegrid Home
              </a>
            </div>
          </div>
        ) : requested ? (
          <div className="flex w-full mt-36 items-center justify-center">
            <div className="grid gap-y-2">
              <ClockCircleOutlined
                className="text-gray-400"
                style={{ fontSize: "50px" }}
              />
              <div className="text-gray-400">Wait until someone let you in</div>
            </div>
          </div>
        ) : disabled ? (
          <div className="flex w-full mt-36 items-center justify-center">
            <div className="grid gap-y-2">
              <WarningOutlined
                className="text-gray-400"
                style={{ fontSize: "50px" }}
              />
              <div className="text-gray-400">
                You don't have access this order
              </div>
            </div>
          </div>
        ) : orderRequested ? (
          <div className="flex w-full mt-36 items-center justify-center">
            <div className="grid gap-y-2">
              <WarningOutlined
                className="text-gray-400"
                style={{ fontSize: "50px" }}
              />
              <div className="text-gray-400">
                Please wait until someone let you in to add order
              </div>
            </div>
          </div>
        ) : orderCancelled ? (
          <div className="flex w-full mt-36 items-center justify-center">
            <div className="grid gap-y-2">
              <WarningOutlined
                className="text-gray-400"
                style={{ fontSize: "50px" }}
              />
              <div className="text-gray-400">Your order is cancelled</div>
            </div>
          </div>
        ) : orderRejected ? (
          <div className="flex w-full mt-36 items-center justify-center">
            <div className="grid gap-y-2">
              <WarningOutlined
                className="text-gray-400"
                style={{ fontSize: "50px" }}
              />
              <div className="text-gray-400">Your order is Rejected</div>
            </div>
          </div>
        ) : billRequested ? (
          <div className="flex w-full mt-36 items-center justify-center">
              <div className="grid gap-y-2">
                  <ClockCircleOutlined
                      className="text-gray-400"
                      style={{ fontSize: "50px" }}
                  />
                  <div className="text-gray-400">You have requested for the bill</div>
              </div>
          </div>
      ) : (
          <div className="px-[4px] md:p-4 py-4">
            <div>
              {data && renderNoSelectedItemsMessage()}
              {anySectionRendered && (
                <div className="flex mb-3">
                  <div className="font-semibold w-1/12"></div>
                  <div className="font-semibold w-7/12">Item</div>
                  <div className="font-semibold w-1/12">No</div>
                  <div className="font-semibold w-3/12 text-right">Price</div>
                </div>
              )}
              {data && renderConfirmedOrderItems()}
              {data && renderAcceptedOrderItems()}
              {data && renderRejectedOrderItems()}
              {data && renderSelectedOrderItems()}
            </div>

            {anySectionRendered && (
              <div>
                <Link to={`/${id[1]}/${id[2]}/${id[3]}/menu`}>
                  <div className="p-2 bg-black text-white text-center border rounded-lg shadow-sm mt-4">
                    <div>Add Items </div>
                  </div>
                </Link>
                <Divider className="my-3" />
                <div>
                  <div className="flex justify-between text-sm md:text-base leading-6">
                    <div>Sub total</div>
                    <div>
                      {currencyFormatter(
                        data?.totalPriceDetails?.subTotal,
                        business?.currency
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between text-sm md:text-base leading-6">
                    <div>Tax</div>
                    <div>
                      {currencyFormatter(
                        data?.totalPriceDetails?.tax,
                        business?.currency
                      )}
                    </div>
                  </div>

                  <div className="flex justify-between text-sm md:text-base leading-6">
                    <div>Service charge</div>
                    <div>
                      {currencyFormatter(
                        data?.totalPriceDetails?.serviceCharge,
                        business?.currency
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between text-sm md:text-base leading-6">
                    <div>Discount</div>
                    <div>
                      {currencyFormatter(
                        data?.totalPriceDetails?.dicount,
                        business?.currency
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between font-bold text-sm md:text-base leading-6">
                    <div>Total</div>
                    <div>
                      {currencyFormatter(
                        data?.totalPriceDetails?.FullTotal,
                        business?.currency
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full right-0 mt-4">
                  {(data?.customerConfirmedOrderDetail?.orderDetails
                    ?.orderItemAccepted.length ||
                    data?.customerConfirmedOrderDetail?.orderDetails
                      ?.orderItemRejected.length) &&
                  !data?.customerConfirmedOrderDetail?.orderDetails
                    ?.orderItemConfirmed.length &&
                  !renderSelectedOrderItems().length ? (
                    <div
                      className="bg-lime-600 text-white p-2 text-center rounded-lg shadow-md cursor-pointer"
                      onClick={() => {
                        setCompleteOrderModal(true);
                      }}
                    >
                      Request Bill
                    </div>
                  ) : renderSelectedOrderItems().length ? (
                    <div
                      className={`bg-lime-600 text-white p-2 text-center rounded-lg shadow-md cursor-pointer`}
                      onClick={() => {
                        setConfirmOrderModal(true);
                      }}
                    >
                      Confirm Order
                    </div>
                  ) : (
                    <div className="text-center text-black p-2">
                      Wait until the restaurant accepts your items
                    </div>
                  )}
                </div>
                <div
                  className=" bg-white text-red-500 p-2 text-center rounded-lg cursor-pointer shadow-md mt-3 mb-14"
                  onClick={() => {
                    setResetCart(true);
                  }}
                >
                  <a>Reset cart</a>
                </div>
              </div>
            )}
          </div>
        )
      ) : (
        <MainLoader />
      )}
      <ConfirmOrderItemsInfoModal
        visible={confirmItemModal}
        data={confirmItem}
        currency={business?.currency}
        handleCancel={() => setConfirmItemModal(false)}
      />
      <AcceptOrderItemsInfoModal
        visible={acceptItemModal}
        data={acceptItem}
        currency={business?.currency}
        handleCancel={() => setAcceptItemModal(false)}
      />
      <RejectOrderItemsInfoModal
        visible={rejectItemModal}
        data={rejectItem}
        currency={business?.currency}
        handleCancel={() => setRejectItemModal(false)}
      />
      <SelectedOrderItemsInfoModal
        visible={selectItemModal}
        data={selectItem}
        currency={business?.currency}
        handleData={(data) => handleData(data)}
        handleCancel={() => setSelectItemModal(false)}
      />

      <ConfirmationModal
        visible={confirmOrderModal}
        handleCancel={() => setConfirmOrderModal(false)}
        handleConfirm={() => {
          sendConfirmOrder();
          setConfirmOrderModal(false);
        }}
        title={"Confirm Order"}
        text={"Are you sure want to confirm the order?"}
      />
      <ConfirmationModal
        visible={completeOrderModal}
        handleCancel={() => setCompleteOrderModal(false)}
        handleConfirm={() => {
          sendCompleteOrder();
        }}
        title={"Request Bill"}
        text={"Are you sure want to Request Bill?"}
      />
      <ConfirmationModal
        visible={resetCart}
        handleCancel={() => setResetCart(false)}
        handleConfirm={() => {
          sendResetOrder();
          setResetCart(false);
        }}
        title={"Reset Cart"}
        text={"Are you sure want to reset the cart?"}
      />
    </>
  );
}
export default Cart2;
