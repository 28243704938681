import {
  CheckCircleOutlined,
  CheckCircleTwoTone,
  ClockCircleOutlined,
  ClockCircleTwoTone,
  CloseCircleOutlined,
  CloseCircleTwoTone,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  PlusCircleOutlined,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";

function StatusInfoModal(props) {
  return (
    <Modal
      title="Help"
      open={props.visible}
      onCancel={() => props.handleCancel()}
      onOk={() => props.handleConfirm()}
      footer={null}
    >
      <div className="grid gap-y-2">
        <div className="flex items-center  gap-2">
          <PlusCircleTwoTone style={{ fontSize: "20px" }}/>
          <div className="font-semibold">Selected</div>
        </div>
        <div className="flex items-center  gap-2">
          <ClockCircleTwoTone twoToneColor="#bae637" style={{ fontSize: "20px" }}/>
          <div className="font-semibold">Confirmed</div>
        </div>
        <div className="flex items-center  gap-2">
          <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: "20px" }}/>
          <div className="font-semibold">Accepted</div>
        </div>
        <div className="flex items-center  gap-2">
          <ExclamationCircleTwoTone twoToneColor="#ff7875" style={{ fontSize: "20px" }}/>
          <div className="font-semibold">Rejected</div>
        </div>
        <div className="flex items-center  gap-2">
          <CloseCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: "20px" }}/>
          <div className="font-semibold">Deleted</div>
        </div>
      </div>
    </Modal>
  );
}

export default StatusInfoModal;
