import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { ArrowRightOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSocket } from "../../providers/SocketProvider";
import { currencyFormatter } from "../../Helpers/currencyFormat";

function SelectedOrderItemsInfoModal(props) {
  const [orderData, setOrderData] = useState([]);
  const socket = useSocket();
  useEffect(() => {
    setOrderData(props.data);
  }, [props.data]);

  const sendRemoveOrderItem = (id, orderId, itemPrice, quantity) => {
    if (!socket) {
      console.log("Socket is not initialized.");
      return;
    }
    const removeOrderItemDetail = {
      id: id,
      orderId: orderId,
    };
    socket?.emit("removeOrderItem", removeOrderItemDetail);
    props.handleData(id, orderId);
    setOrderData((prevOrderData) => {
      let totalPrice = prevOrderData.price - itemPrice;
      const updatedOrderBy = prevOrderData.orderBy.map((order) => {
        order.orderItem = order.orderItem.filter((item) => {
          if (item.id === id) {
            return false;
          }
          return true;
        });
        return order;
      });
      const filteredOrderBy = updatedOrderBy.filter(
        (order) => order.orderItem.length > 0
      );
      if (filteredOrderBy.length === 0) {
        props.handleCancel();
      }
      return {
        ...prevOrderData,
        orderBy: filteredOrderBy,
        price: totalPrice,
      };
    });
  };

  return (
    <div>
      <Modal
        open={props.visible}
        footer={null}
        onCancel={() => props.handleCancel()}
        onOk={() => props.handleConfirm()}
        title="Order Details"
        destroyOnClose={true}
      >
        <div className="text-base font-medium flex items-center justify-between mb-2">
          <span>{orderData?.menuName} </span>
          <span>{currencyFormatter(orderData?.price, props?.currency)}</span>
        </div>
        <div>
          {orderData?.orderBy?.map((value, index) => (
            <div key={index} className="mt-1">
              {value.orderItem.length > 0 && (
                <span className="text-base font-medium">{value.name}</span>
              )}
              {value.orderItem?.map((item, itemIndex) => (
                <div
                  key={itemIndex}
                  className="flex items-center justify-between mb-3"
                >
                  {item?.modifierItem.length === 0 ? (
                    <>
                      <span className="w-1/2 md:w-3/4 text-xs text-gray-600">
                        {value.name}
                      </span>
                      <span className="font-medium mr-4 text-sm">
                        {item?.quantity}
                      </span>
                      <div
                        className="text-red-600 cursor-pointer border border-red-600 rounded-sm p-[2px]"
                        onClick={() => {
                          sendRemoveOrderItem(
                            item.id,
                            item.orderId,
                            item.price,
                            item.quantity
                          );
                        }}
                      >
                        <DeleteOutlined className="text-sm flex justify-start" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-1/2 md:w-3/4 text-xs text-gray-600">
                        <div className="flex flex-wrap items-center justify-start text-xs">
                          {item?.modifierItem.map(
                            (modifierItem, modifierItemIndex) => (
                              <div key={modifierItemIndex} className="">
                                {modifierItem?.modifier.map(
                                  (modifier, modifierIndex) => (
                                    <span key={modifierIndex}>
                                      {modifierIndex !== 0 && ", "}
                                      <span>{modifier.name}: </span>(
                                      {currencyFormatter(
                                        modifier.price,
                                        props?.currency
                                      )}
                                      )
                                    </span>
                                  )
                                )}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="font-medium mr-4 text-sm">
                        {item?.quantity}
                      </div>
                      <div
                        className="text-red-600 cursor-pointer border border-red-600 rounded-sm p-[2px]"
                        onClick={() => {
                          sendRemoveOrderItem(
                            item.id,
                            item.orderId,
                            item.price,
                            item.quantity
                          );
                        }}
                      >
                        <DeleteOutlined className="text-sm flex justify-start" />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

export default SelectedOrderItemsInfoModal;
