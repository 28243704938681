import React from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

function OpenRoute() {
  const curLocation = useLocation();

  const path = curLocation.pathname;
  const id = path.split("/");

  if (localStorage.getItem("userId")) {
    return <Navigate to={`/${id[1]}/${id[2]}/${id[3]}/menu`} replace />;
  } else {
    return <Outlet />;
  }
}

export default OpenRoute;
