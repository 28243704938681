import { Modal } from "antd";
import React from "react";

function ConfirmationModal(props) {
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.handleCancel()}
      footer={null}
      title={props.title}
    >
      <div className="mb-3">{props.text}</div>
      <div
        className="bg-lime-700 text-white p-2 text-center rounded-lg shadow-md mb-2 mt-2 cursor-pointer"
        onClick={() => props.handleConfirm()}
      >
        {props.title}
      </div>
      <div
        className="bg-white text-blue-500 pt-2 text-center"
        onClick={() => props.handleCancel()}
      >
        Cancel
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
