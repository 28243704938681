import React, { createContext, useEffect, useContext } from 'react';
import io from 'socket.io-client';

const SOCKET_URL = `${process.env.REACT_APP_API_URL}`;

const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const socket = io(SOCKET_URL)

  useEffect(() => {
    return () => {
        socket.disconnect();
    };
  }, []);
  
  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};

export default SocketContext;