import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import WebLayout from "../layouts/WebLayout";
import UserInfo from "../views/UserInfo";
import Menu from "../views/Menu";
import MainLayout from "../layouts/MainLayout";
import Cart from "../views/Cart";
import Profile from "../views/Profile";
import InvoiceLayout from "../layouts/InvoiceLayout";
import Invoice from "../views/Invoice";
import { useState, useEffect } from "react";
import OpenRoute from "./OpenRoute";
import ProtectedRoute from "./ProtectedRoute";
import Initial from "../views/Initial";
import Gratitude from "../views/Gratitude";
import { useSocket } from "../providers/SocketProvider";

function Main() {
  const [menuData, setMenuData] = useState([]);
  const [confirmOrderdata, setConfirmOrderData] = useState([]);
  const [acceptOrderItemData, setAcceptOrderItemData] = useState([]);
  const [rejectOrderItemData, setRejectOrderItemData] = useState([]);
  const [completeOrderData, setCompleteOrderData] = useState([]);
  const [closeOrderData, setCloseOrderData] = useState();
  const [cancelOrderData, setCancelOrderData] = useState([]);
  const [getRealtimeData, setGetRealtimeData] = useState([]);
  const [enableCustomerAdminData, setEnableCustomerAdminData] = useState();
  const [disableCustomerAdminData, setDisableCustomerAdminData] = useState();
  const [enableCustomerByAnotherData, setEnableCustomerByAnotherData] =
    useState();
  const [disableCustomerByAnotherData, setDisableCustomerByAnotherData] =
    useState();
  const [acceptOrderData, setAcceptOrderData] = useState();
  const [rejectOrderData, setRejectOrderData] = useState();
  const [addCustomerData, setAddCustomerData] = useState();
  const curLocation = useLocation();
  const socket = useSocket();
  const path = curLocation.pathname;
  const id = path.split("/");
  const navigate = useNavigate();

  useEffect(() => {
    sendGetOrder();
  }, [menuData, acceptOrderData, rejectOrderData]);

  useEffect(() => {
    if (
      (localStorage.getItem("userId") && id[3] === "info") ||
      (localStorage.getItem("userId") && id[3] === "undefined")
    ) {
      sendCreateOrder();
    }
  }, [localStorage.getItem("userId"), id[3]]);

  // useEffect(() => {
  //   if (id[3] === 'undefined') {
  //     socket?.on("create-order-with-customer", createOrder)
  //   }
  // }, [id[3]]);

  const handleData = (newData) => {
    setMenuData(newData);
  };

  const messageListener = (data) => {
    setMenuData(data?.data);
  };

  const createOrder = (data) => {
    if (data.success) {
      if (data?.data?.qrId === id[2]) {
        const newUrl = `/${id[1]}/${id[2]}/${data?.data?.id}/`;
        navigate(newUrl);
      }
    } else if (data.message === "Customer not found") {
      localStorage.removeItem("userId");
      const newUrl = `/${id[1]}/${id[2]}/info`;
      navigate(newUrl);
    }
  };

  const confirmOrder = (data) => {
    setConfirmOrderData(data);
  };

  const acceptOrderItem = (data) => {
    setAcceptOrderItemData(data?.data);
  };

  const rejectOrderItem = (data) => {
    setRejectOrderItemData(data?.data);
  };

  const completeOrder = (data) => {
    setCompleteOrderData(data?.data);
  };

  const closeOrder = (data) => {
    setCloseOrderData(data?.data);
  };

  const cancelOrder = (data) => {
    setOrderCancelled(true);
    setCancelledMessage('your order is cancelled');
    setCancelOrderData(data?.data);
  };

  const enableCustomerAdmin = (data) => {
    setEnableCustomerAdminData(data?.data);
  };

  const disableCustomerAdmin = (data) => {
    setDisableCustomerAdminData(data?.data);
  };

  const enableCustomerByAother = (data) => {
    setEnableCustomerByAnotherData(data?.data);
  };

  const disableCustomerByAother = (data) => {
    setDisableCustomerByAnotherData(data?.data);
  };

  const acceptOrder = (data) => {
    setAcceptOrderData(data?.data);
  };

  const rejectOrder = (data) => {
    setRejectOrderData(data?.data);
  };

  const addCustomerName = (data) => {
    setAddCustomerData(data?.data);
  };

  const sendGetOrder = () => {
    socket?.emit("getOrder", getOrderDetail);
  };

  const sendCreateOrder = () => {
    socket?.emit("create-order-with-customer", createOrderDetail);
  };

  const getOrderDetail = {
    orderId: id[3],
    customerId: localStorage.getItem("userId"),
  };

  const createOrderDetail = {
    qrId: id[2],
    customerId: localStorage.getItem("userId"),
  };

  const getOrder = (data) => {
    setGetRealtimeData(data);
  };

  useEffect(() => {
    socket?.on("create-order-with-customer", createOrder);
    socket?.on("addOrders", messageListener);
    socket?.on("editOrders", messageListener);
    socket?.on("removeOrderItem", messageListener);
    socket?.on("resetOrder", messageListener);
    socket?.on("confirmOrder", confirmOrder);
    socket?.on("acceptOrderItem", acceptOrderItem);
    socket?.on("rejectOrderItem", rejectOrderItem);
    socket?.on("completeOrder", completeOrder);
    socket?.on("closeOrder", closeOrder);
    socket?.on("cancelOrder", cancelOrder);
    socket?.on("getOrder", getOrder);
    socket?.on("enable-customer-admin", enableCustomerAdmin);
    socket?.on("disable-customer-admin", disableCustomerAdmin);
    socket?.on("enable-customer-by-another", enableCustomerByAother);
    socket?.on("disable-customer-by-another", disableCustomerByAother);
    socket?.on("acceptOrder", acceptOrder);
    socket?.on("rejectOrder", rejectOrder);
    socket?.on("add-customer-name", addCustomerName);

    return () => {
      socket?.off("create-order-with-customer", createOrder);
      socket?.off("addOrders", messageListener);
      socket?.off("editOrders", messageListener);
      socket?.off("removeOrderItem", messageListener);
      socket?.off("resetOrder", messageListener);
      socket?.off("confirmOrder", confirmOrder);
      socket?.off("acceptOrderItem", acceptOrder);
      socket?.off("rejectOrderItem", rejectOrder);
      socket?.off("completeOrder", completeOrder);
      socket?.off("closeOrder", closeOrder);
      socket?.off("cancelOrder", cancelOrder);
      socket?.off("getOrder", getOrder);
      socket?.off("enable-customer-admin", enableCustomerAdmin);
      socket?.off("disable-customer-admin", disableCustomerAdmin);
      socket?.off("enable-customer-by-another", enableCustomerByAother);
      socket?.off("disable-customer-by-another", disableCustomerByAother);
      socket?.off("acceptOrder", acceptOrder);
      socket?.off("rejectOrder", rejectOrder);
      socket?.off("add-customer-name", addCustomerName);
    };
  }, []);

  const [orderCancelled, setOrderCancelled] = useState(false);
  const [cancelledMessage, setCancelledMessage] = useState("");
  useEffect(() => {
    if (
      getRealtimeData &&
      (getRealtimeData.message === "your order is rejected" ||
        getRealtimeData.message === "your order is cancelled")
    ) {
      setOrderCancelled(true);
      setCancelledMessage(getRealtimeData?.message);
    }
  }, [getRealtimeData, getRealtimeData?.message]);

  const createNewOrder = () => {
    // localStorage.removeItem("userId");
    setOrderCancelled(false);
    setCancelledMessage("");
    navigate(`/${id[1]}/${id[2]}/info`);
  };

  const renderCancelledOrder = () => {
    return (
      <div className="flex flex-col justify-center items-center xl:gap-[20px] gap-[12px] mt-[120px]">
        <div className="xl:text-lg text-sm text-gray-500 capitalize font-semibold">
          {cancelledMessage}
        </div>
        <div className="xl:text-lg text-sm text-black font-medium">
          Do you want to create new order?
        </div>
        <div
          className="bg-lime-700 text-white p-2 text-center rounded-lg hover:shadow cursor-pointer"
          onClick={createNewOrder}
        >
          Create New Order
        </div>
      </div>
    );
  };
  return (
    <Routes>
      <Route element={<OpenRoute />}>
        <Route path="/" element={<WebLayout />}>
          <Route path="initial" element={<Initial />} />
          <Route path={`/${id[1]}/${id[2]}/info`} element={<UserInfo />} />
          <Route
            path="/"
            element={<Navigate replace to={`/${id[1]}/${id[2]}/info`} />}
          />
        </Route>
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route
          path={"*"}
          element={<Navigate to={`/${id[1]}/${id[2]}/${id[3]}/menu`} />}
        />
        <Route
          path="/"
          element={
            <MainLayout
              addCustomerData={addCustomerData}
              data={menuData}
              getRealtimeData={getRealtimeData}
            />
          }
        >
          <Route
            path={`/${id[1]}/${id[2]}/${id[3] && id[3]}/menu`}
            element={
              !orderCancelled ? (
                <Menu
                  menuDataFunction={(values) => handleData(values)}
                  data={menuData}
                  confirmData={confirmOrderdata}
                  acceptData={acceptOrderItemData}
                  rejectData={rejectOrderItemData}
                  rejectOrderData={rejectOrderData}
                  getRealtimeData={getRealtimeData}
                />
              ) : (
                renderCancelledOrder()
              )
            }
          />
          <Route
            path={`/${id[1]}/${id[2]}/${id[3]}/cart`}
            element={
              <Cart
                data={menuData}
                confirmData={confirmOrderdata}
                acceptData={acceptOrderItemData}
                rejectData={rejectOrderItemData}
                rejectOrderData={rejectOrderData}
                handleData={(values) => handleData(values)}
                completeData={completeOrderData}
                cancelOrderData={cancelOrderData}
                getRealtimeData={getRealtimeData}
                enableCustomerAdmin={enableCustomerAdminData}
                disableCustomerAdmin={disableCustomerAdminData}
                enableCustomerByAnother={enableCustomerByAnotherData}
                disableCustomerByAnother={disableCustomerByAnotherData}
                closeData={closeOrderData}
              />
            }
          />
          <Route
            path={`/${id[1]}/${id[2]}/${id[3]}/profile`}
            element={<Profile />}
          />
          {/* <Route path="/*" element={<Navigate replace to="/menu" />} /> */}
        </Route>
        <Route path="/" element={<InvoiceLayout />}>
          <Route
            path={`/${id[1]}/${id[2]}/${id[3]}/invoice`}
            element={
              <Invoice
                completeData={completeOrderData}
                closeData={closeOrderData}
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default Main;
