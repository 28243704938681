import {
  ProfileOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Badge } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { GetOrderItemApi } from "../../api/GetOrderItemApi";

function BottomMenubar(props) {
  const [mainKey, setMainKey] = useState("");
  const [data, setData] = useState();
  const curLocation = useLocation();
  const path = curLocation.pathname;
  const id = path.split("/");
  const paths = [
    { path: `/${id[1]}/${id[2]}/${id[3]}/menu`, key: "1" },
    { path: `/${id[1]}/${id[2]}/${id[3]}/cart`, key: "2" },
    { path: `/${id[1]}/${id[2]}/${id[3]}/profile`, key: "3" },
  ];

  useEffect(() => {
    setMainKey("");
    paths.forEach((path) => {
      if (path.path === curLocation.pathname) {
        setMainKey(path.key);
      }
    });
  }, [curLocation]);

  useEffect(() => {
    getOrder();
  }, [props.getRealtimeData, props.data]);

  async function getOrder() {
    const apiResponse = await GetOrderItemApi(
      id[3],
      localStorage.getItem("userId")
    );
    setData(apiResponse?.data);
  }

  const dataLength =
    data?.customerSelectedOrderDetail?.orderDetails?.orderSelectedCartDetail?.length +
    data?.customerConfirmedOrderDetail?.orderDetails?.orderItemAccepted?.length +
    data?.customerConfirmedOrderDetail?.orderDetails?.orderItemConfirmed?.length +
    data?.customerConfirmedOrderDetail?.orderDetails?.orderItemRejected?.length ||
    0;

  return (
    <>
      <div className="px-2 bg-white shadow flex justify-between items-center">
        <Link
          to={`/${id[1]}/${id[2]}/${id[3]}/menu`}
          className={
            "flex-1 text-center py-2 lg:py-3" +
            (mainKey === "1" )
          }
        >
          <div className={`flex flex-col items-center ${mainKey === "1" ? "text-lime-700 font-bold" : "text-black"} hover:text-lime-700 pt-4`}>
            <ProfileOutlined />
            <div className="text-xs">Menu</div>
          </div>
        </Link>
        <Link
          to={`/${id[1]}/${id[2]}/${id[3]}/cart`}
          className={
            "flex-1 text-center py-2 lg:py-3" +
            (mainKey === "2")
          }
        >
          <div className={`flex flex-col items-center ${mainKey === "2" ? "text-lime-700 font-bold" : "text-black"} hover:text-lime-700 pt-4`}>
            <Badge count={dataLength} size="small" style={{ backgroundColor: "#4d7c0f" }}>
              <ShoppingCartOutlined className={mainKey === "2" ? "text-lime-700" : "text-black hover:text-lime-700"} />
            </Badge>
            <div className="text-xs">Cart</div>
          </div>
        </Link>
        <Link
          to={`/${id[1]}/${id[2]}/${id[3]}/profile`}
          className={
            "flex-1 text-center py-2 lg:py-3" +
            (mainKey === "3")
          }
        >
          <div className={`flex flex-col items-center ${mainKey === "3" ? "text-lime-700 font-bold" : "text-black"} hover:text-lime-700 pt-4`}>
            <ShopOutlined />
            <div className="text-xs">Profile</div>
          </div>
        </Link>
      </div>
    </>
  );
}
export default BottomMenubar;
