import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import Navbar from "../components/layout/Navbar";
import BottomMenubar from "../components/layout/BottomMenubar";


function MainLayout(props) {
  const { Content } = Layout;

  return (
    <div className="container">
      <Layout style={{ backgroundColor: "#fff" }}>
        <Navbar addCustomerData={props.addCustomerData}/>
        <Content>
          <Outlet />
        </Content>
        <div className="bottom-0 w-full fixed left-0">
          <BottomMenubar data={props.data} getRealtimeData={props.getRealtimeData}/>
        </div>
      </Layout>
    </div>
  );
}

export default MainLayout;
