import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Tag, Button } from "antd";
import React, { useState } from "react";
import { STATUS } from "../../Helpers/constants";
import ConfirmationModal from "./ConfirmationModal";
import { useSocket } from "../../providers/SocketProvider";
import { useLocation } from "react-router-dom";

function AnotherCustomerStatusModal(props) {
  const curLocation = useLocation();

  const path = curLocation.pathname;
  const id = path.split("/");
  const [customerId, setCustomerId] = useState();
  const [enableCustomerModal, setEnableCustomerModal] = useState();
  const [disableCustomerModal, setDisableCustomerModal] = useState();

  const socket = useSocket();

  const sendEnableCustomerByAnother = () => {
    socket?.emit("enable-customer-by-another", enableCustomerByAnotherDetail);
  };

  const sendDisableCustomerByAnother = () => {
    socket?.emit("disable-customer-by-another", disableCustomerByAnotherDetail);
  };

  const enableCustomerByAnotherDetail = {
    orderId: id[3],
    anotherCustomerId: customerId,
    myCustomerId: localStorage.getItem("userId"),
  };

  const disableCustomerByAnotherDetail = {
    orderId: id[3],
    anotherCustomerId: customerId,
    myCustomerId: localStorage.getItem("userId"),
  };

  function getCustomerData() {
    let output = [];
    const currentUser = localStorage.getItem("userId");
    const currentUserData = props.data?.find(data => data.customerId === currentUser);
    const currentUserStatus = currentUserData?.status;

    if (!props.data || !currentUserData) {
      return output;
    }
  
    if (currentUserStatus === STATUS.Disabled) {
      output.push(
        <div key="disabled-status" className="flex items-baseline text-base font-medium text-gray-500">
          <ExclamationCircleOutlined className="text-gray-400 text-lg mr-1"/>
          You don't have access this order
        </div>
      );
    } else if (currentUserStatus === STATUS.Requested) {
      output.push(
        <div  key="requested-status" className="flex items-baseline text-base font-medium text-gray-500">
          <ClockCircleOutlined className="text-gray-400 text-lg mr-1"/>
          Wait until someone let you in
        </div>
      );
    } else {
      props.data.forEach((data, index) => {
        output.push(
          <div key={index}  className="grid grid-cols-3 items-center gap-4 mt-2">
            <div>{data.customerName}</div>
            <div>
              {data.customerId === currentUser ? (
                <Tag color={getStatusColor(currentUserStatus)}>{getStatusText(currentUserStatus)}</Tag>
              ) : (
                data.status === STATUS.Requested ? (
                  <Tag color={"orange"}>Requested</Tag>
                ) : data.status === STATUS.Enabled ? (
                  <Tag color="green">Enabled</Tag>
                ) : (
                  <Tag color="red">Disabled</Tag>
                )
              )}
            </div>
            {data.customerId !== currentUser && currentUserStatus === STATUS.Enabled && (
              <div className="flex w-2/8 gap-2">
                {data.status !== STATUS.Enabled && (
                  <div
                    className="text-green-400"
                    onClick={() => {
                      setCustomerId(data.customerId);
                      setEnableCustomerModal(true);
                    }}
                  >
                    <Button
                      size="small"
                      style={{ borderColor: "#4ADE80" }}
                      className="flex items-center justify-center"
                      icon={<CheckCircleOutlined className="text-green-400" />}
                    />
                  </div>
                )}
                {data.status !== STATUS.Disabled && (
                  <div
                    className="text-red-400"
                    onClick={() => {
                      setCustomerId(data.customerId);
                      setDisableCustomerModal(true);
                    }}
                  >
                    <Button
                      danger
                      size="small"
                      className="flex items-center justify-center"
                      icon={<CloseCircleOutlined className=" text-red-400" />}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        );
      });
    }
    return output;
  }
  function getStatusColor(status) {
    return status === STATUS.Requested ? "orange" : status === STATUS.Enabled ? "green" : "red";
  }
  
  function getStatusText(status) {
    return status === STATUS.Requested ? "Requested" : status === STATUS.Enabled ? "Enabled" : "Disabled";
  }
  

  return (
    <>
      <Modal
        title="Customers"
        open={props.visible}
        onCancel={props.handleCancel}
        footer={null}
        destroyOnClose={true}
      >
        <div>{getCustomerData()}</div>
      </Modal>
      <ConfirmationModal
        visible={enableCustomerModal}
        handleCancel={() => setEnableCustomerModal(false)}
        handleConfirm={() => {
          sendEnableCustomerByAnother();
          setEnableCustomerModal(false);
        }}
        text="Are you sure want to enable this customer?"
        title="Enable customer"
      />
      <ConfirmationModal
        visible={disableCustomerModal}
        handleCancel={() => setDisableCustomerModal(false)}
        handleConfirm={() => {
          sendDisableCustomerByAnother();
          setDisableCustomerModal(false);
        }}
        text="Are you sure want to disable this customer?"
        title="Disable customer"
      />
    </>
  );
}

export default AnotherCustomerStatusModal;
