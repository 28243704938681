import React, { useEffect, useState } from "react";
import Item from "../components/Cards/Item";
import { Link, Element } from "react-scroll";
import {
  CloseCircleFilled,
  InboxOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import MainLoader from "../components/Loader/MainLoader";
import { GetAllInfoApi } from "../api/GetAllInfoApi";
import { useLocation } from "react-router-dom";

function Menu(props) {
  const [search, setSearch] = useState(false);
  const [query, setQuery] = useState("");
  const [searchStart, setSearchStart] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noItems, setNoItems] = useState(false);
  const [menuData, setMenuData] = useState([]);

  const curLocation = useLocation();

  const path = curLocation.pathname;
  const id = path.split("/");

  useEffect(() => {
    getCategory();
  }, []);

  const handleData = (newData) => {
    setMenuData(newData);
    props.menuDataFunction(newData);
  };

  async function getCategory() {
    setLoading(true);
    const apiResponse = await GetAllInfoApi(
      id[1],
      localStorage.getItem("userId")
    );
    if (apiResponse.success) {
      setData(apiResponse.data);
      setLoading(false);
    }
  }

  function renderMenuItems() {
    let output = [];

    data?.categories?.sort((a, b) => {
      return a.position - b.position;
    });

    data?.categories?.forEach((category, index) => {
      output.push(
        <Element key={index} name={category?.name}>
          <div className="font-bold border-b mb-4 md:mb-5 text-base lg:text-lg">
            {category?.name}
          </div>

          <Item
            data={category}
            handleData={(data) => handleData(data)}
            currency={data?.currency}
            rejectOrder={props.rejectOrderData}
            realtimeData={props.data}
            confirmRealtimeData={props.confirmData}
            getRealtimeData={props.getRealtimeData}
          />
        </Element>
      );
    });
    return output;
  }

  function renderCategories() {
    let output = [];
  
    data?.categories?.forEach((data, index) => {
      output.push(
        <div key={index} className="mr-6">
          <Link
            offset={-64}
            to={data?.name}
            spy={true}
            smooth={true}
            duration={500}
            activeClass="active-category"
          >
            <div className="w-max h-6">{data?.name}</div>
          </Link>
        </div>
      );
    });
  
    return output;
  }
  

  function renderSearchItems() {
    let output = [];

    data?.categories?.forEach((category, index) => {
      const filteredItems = category?.menuItems?.filter((item) => {
        if (query && item.name.toLowerCase().includes(query.toLowerCase())) {
          return item;
        }
      });

      const items = { menuItems: filteredItems };
      output.push(
        <Item
          data={items}
          handleData={(data) => handleData(data)}
          currency={data?.currency}
          realtimeData={props.data}
          confirmRealtimeData={props.confirmData}
          getRealtimeData={props.getRealtimeData}
        />
      );
    });
    return output;
  }

  function inputSearch(value) {
    let searchedItems = [];

    setQuery(value);
    if (value) {
      setSearchStart(true);
    } else {
      setSearchStart(false);
    }
    if (data && data.categories) {
      data.categories.forEach((category) => {
        if (category && category.menuItems) {
          const filteredItems = category.menuItems
            .filter((item) => {
              if (
                query &&
                item.name.toLowerCase().includes(query.toLowerCase())
              ) {
                return item;
              }
            })
            .forEach((searchedItem) => {
              searchedItems.push(searchedItem);
            });
        }
      });

      if (searchedItems.length) {
        setNoItems(false);
      } else {
        setNoItems(true);
      }
    }
  }

  return (
    <>
      {!loading ? (
        search ? (
          <>
            <div>
              <div className="px-4 py-2 shadow sticky top-0 bg-white">
                <Input
                  onChange={(event) => inputSearch(event.target.value)}
                  data-search
                  size="large"
                  placeholder="Search"
                  className="w-full"
                  suffix={
                    <CloseCircleFilled
                      onClick={() => {
                        setSearch(false);
                        setSearchStart(false);
                        setQuery("");
                      }}
                      style={{
                        color: "rgba(0,0,0,.45)",
                      }}
                    />
                  }
                />
              </div>
              {searchStart || query !== "" ? (
                noItems ? (
                  <div className="flex justify-center items-center w-full h-full my-48">
                    <div className="text-center text-gray-500">
                      <div>
                        <InboxOutlined className="text-3xl" />
                      </div>
                      No items available
                    </div>
                  </div>
                ) : (
                  <div className="px-[4px] mt-2 overflow-hidden mb-10">
                    {renderSearchItems()}
                  </div>
                )
              ) : (
                <div className="px-[4px] md:p-4 mb-12">{renderMenuItems()}</div>
              )}
            </div>
          </>
        ) : (
          <>
            <div style={{ top: 0 }} className="sticky top-0 bg-white">
              <div className="shadow flex overflow-scroll items-center invisibile-scroll mb-2">
                <div
                  className="flex items-center absolute bg-white pl-2 md:pl-4 pr-2 md:pr-3 py-3"
                  onClick={() => setSearch(true)}
                >
                  <SearchOutlined className="font-bold" />
                </div>
                <div className="flex ml-7 md:ml-8 py-4 px-[6px] md:p-4">{renderCategories()}</div>
              </div>
            </div>
            <div className="px-[4px] md:p-4 mb-12">{renderMenuItems()}</div>
          </>
        )
      ) : (
        <MainLoader />
      )}
    </>
  );
}
export default Menu;
