import { Divider, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { GetCompleteOrderApi } from "../api/GetCompleteOrderApi";
import { useLocation, useNavigate } from "react-router-dom";
import { GetAllInfoApi } from "../api/GetAllInfoApi";
import logo from "../assets/images/Dinegrid.png";
import { usePDF } from "react-to-pdf";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { STATUS } from "../Helpers/constants";
import { GetOrderItemApi } from "../api/GetOrderItemApi";

function Invoice(props) {
  const [businessData, setBusinessData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const curLocation = useLocation();
  const path = curLocation.pathname;
  const id = path.split("/");

  useEffect(() => {
    getOrder();
    getAllInfo();
    getInvoiceDataInfo();
  }, []);

  async function getAllInfo() {
    const apiResponse = await GetAllInfoApi(
      id[1],
      localStorage.getItem("userId")
    );

    if (apiResponse.success) {
      setBusinessData(apiResponse.data);
    }
  }

  async function getOrder() {
    const apiResponse = await GetOrderItemApi(
      id[3],
      localStorage.getItem("userId")
    );
    if (apiResponse.message === "you are in requested status") {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    } else if (apiResponse.message === "Not Acceptable") {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    } else if (apiResponse.message === "Requested in order") {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    } else if (apiResponse.message === "your order is cancelled") {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    } else if (apiResponse.message === "your order is rejected") {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    } else if (
      apiResponse.success &&
      apiResponse.data?.adminDetail?.orderStatus === STATUS.Closed
    ) {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/invoice`);
    } else if (
      apiResponse.success &&
      apiResponse.data?.adminDetail?.orderStatus === STATUS.Active
    ) {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    }
  }

  async function getInvoiceDataInfo() {
    const apiResponse = await GetCompleteOrderApi(id[3]);
    if (apiResponse.success) {
      setInvoiceData(apiResponse.data);
      setLoading(false);
    }
  }

  function renderCompleteItems() {
    return invoiceData.finalInvoice?.itemDetails.map((item) => (
      <div key={item.id} className="flex flex-col mb-2">
        <div className="flex">
          <div className="font-semibold text-sm md:text-base w-8/12">
            {item.menuName}
          </div>
          <div className="font-semibold text-sm md:text-base w-1/12">
            {item.quantity}
          </div>
          <div className="font-semibold text-sm md:text-base w-3/12 text-right">
            {businessData.currency} {item?.price ? Number(item.price).toFixed(2) : 0}
          </div>
        </div>
        {item.orderBy.map((order) => (
          <div key={order.customerId} className="ml-3 md:ml-4">
            <div className="font-semibold text-sm md:text-base">
              {order.name}
            </div>
            {order.modifier_order_items.map((modifierItem) => (
              <div key={modifierItem.id}>
                <div className="w-8/12 ml-2 md:ml-4 text-sm leading-5">
                  {modifierItem.name}
                </div>
                {modifierItem.modifier.map((item) => (
                  <div
                    key={item.id}
                    className="flex justify-between ml-4 md:ml-6"
                  >
                    <div className="text-xs leading-5">{item.name}</div>
                    <div className="text-right text-xs leading-5">
                      {businessData.currency} {item?.price ? Number(item.price).toFixed(2) : 0}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
    ));
  }

  const currentDate = new Date();
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = currentDate.getDate();
  const month = monthNames[currentDate.getMonth()];
  const year = currentDate.getFullYear();
  const formattedDate = `${day} ${month} ${year}`;

  const { toPDF, targetRef } = usePDF({
    filename: `${businessData.name}_invoice.pdf`,
  });

  return (
    <>
      {loading ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "200px" }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div className="bg-gray-100 p-[4px] md:p-4 rounded-md">
          <div ref={targetRef}>
            <div className="px-4 py-2 border-b">
              <div className="flex items-center justify-between">
                <div className="text-3xl logo">
                  <img
                    src={logo}
                    className="h-10 md:h-12 object-cover"
                    alt="Logo"
                  />
                  <span>{businessData.name}</span>
                </div>
                <div className="text-xs text-right">
                  <div>
                    Total{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {invoiceData.finalInvoice?.priceDetails?.FullTotal.toFixed(
                        2
                      )}
                    </span>
                  </div>
                  <span>{formattedDate}</span>
                </div>
              </div>
              <span className="py-2 md:py-5 text-sm">
                Here's Your bill for {businessData.name}
              </span>
            </div>
            <div className="p-4">
              <div className="flex mb-2">
                <div className="font-semibold w-8/12">Item</div>
                <div className="font-semibold w-1/12">No</div>
                <div className="font-semibold w-3/12 text-right">Price</div>
              </div>
              {renderCompleteItems()}
              <Divider className="my-3" />
              <div className="text-sm md:text-base">
                <div className="flex justify-between mb-2">
                  <div>Sub total</div>
                  <div>
                    {businessData.currency}{" "}
                    {invoiceData.finalInvoice?.priceDetails?.subTotal.toFixed(
                      2
                    )}
                  </div>
                </div>
                <div className="flex justify-between mb-2">
                  <div>Tax</div>
                  <div>
                    {businessData.currency}{" "}
                    {invoiceData.finalInvoice?.priceDetails?.tax.toFixed(2)}
                  </div>
                </div>
                <div className="flex justify-between mb-2">
                  <div>Service charge</div>
                  <div>
                    {businessData.currency}{" "}
                    {invoiceData.finalInvoice?.priceDetails?.serviceCharge.toFixed(
                      2
                    )}
                  </div>
                </div>
                <div className="flex justify-between mb-2">
                  <div>Discount</div>
                  <div className="text-green-500">
                    {businessData.currency}{" "}
                    {(
                      invoiceData.finalInvoice?.priceDetails?.discount || 0
                    ).toFixed(2)}
                  </div>
                </div>
                <div className="flex justify-between font-bold mb-2">
                  <div>Total</div>
                  <div>
                    {businessData.currency}{" "}
                    {invoiceData.finalInvoice?.priceDetails?.FullTotal.toFixed(
                      2
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <p className="text-base mb-4 text-gray-600">
                Thank you for dining with{" "}
                <span className="text-orange-500 font-bold">Dinegrid</span>. We
                hope you enjoyed your meal!
              </p>
            </div>
          </div>

          <Divider className="my-3" />
          <div>
            <button onClick={() => toPDF()}>
              <span className="flex items-center gap-1">
                <CloudDownloadOutlined className="font-semibold" />
                Download PDF
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
}
export default Invoice;
