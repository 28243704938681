import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal } from "antd";
import React, { useEffect, useState } from "react";

import { useSocket } from "../../providers/SocketProvider";

function EditOrderItemModal(props) {
  const [qty, setQty] = useState(1);
  const [submit, setSubmit] = useState(false);
  const socket = useSocket();

  const send = (values) => {
    const editOrderDetail = {
      customerId: localStorage.getItem("userId"),
      menuItemId: props.data?.menuItemId,
      note: values.note,
      quantity: qty,
      orderId: props.data?.orderId,
      id: props.data.id,
    };

    socket?.emit("editOrders", editOrderDetail);
    props.handleCancel();
  };

  const sendRemoveOrderItem = () => {
    socket?.emit("removeOrderItem", removeOrderItemDetail);
    props.handleCancel();
  };

  const removeOrderItemDetail = {
    id: props.data?.id,
  };

  useEffect(() => {
    socket?.on("editOrders");
    socket?.on("removeOrderItem");

    return () => {
      socket?.off("editOrders");
      socket?.off("removeOrderItem");
    };
  }, [socket]);

  useEffect(() => {
    setQty(props.data?.quantity);
  }, [props.data?.quantity]);

  const handleMinus = () => {
    if (qty === 0) return;
    setQty(qty - 1);
  };

  const handlePlus = () => {
    setQty(qty + 1);
  };

  return (
    <Modal
      open={props.visible}
      footer={null}
      onCancel={() => props.handleCancel()}
      onOk={() => props.handleConfirm()}
      title="Edit Item"
      destroyOnClose={true}
    >
      <Form
        name="editOrderItem"
        onFinish={send}
        autoComplete="off"
        requiredMark={false}
        initialValues={{ note: props.data?.note }}
      >
        <div className="">
          <div className="font-bold">{props.data?.menuItemDetails?.name}</div>
          <div className="font-semibold">Rs {props.data?.price}</div>
          <div className="mb-3">{props.data?.menuItemDetails?.description}</div>

          <div className="mb-1">Add a note</div>
          <Form.Item name="note">
            <Input.TextArea rows={2} />
          </Form.Item>

          <div className="flex justify-center items-center mt-3 mb-3">
            <MinusOutlined
              className="border-2 border-black rounded-full p-2"
              onClick={handleMinus}
            />

            <div className="p-3 text-xl">{qty}</div>

            <PlusOutlined
              className="border-2 border-black rounded-full p-2"
              onClick={handlePlus}
            />
          </div>
          <div className="grid justify-items-stretch">
            <Button
              className=" bg-lime-700 mb-2"
              type="primary"
              htmlType={submit && "submit"}
            >
              Edit
            </Button>
          </div>
          <div
            className="bg-white text-blue-500 pt-2 text-center cursor-pointer"
            onClick={() => props.handleCancel()}
          >
            Cancel
          </div>
          <div
            className="bg-white text-red-500 pt-2 text-center cursor-pointer"
            onClick={() => {
              sendRemoveOrderItem();
            }}
          >
            Remove Item
          </div>
        </div>
      </Form>
    </Modal>
  );
}
export default EditOrderItemModal;
