import React, { useEffect, useState } from "react";
import {
  InfoCircleOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ChangeName from "../Modals/ChangeName";
import StatusInfoModal from "../Modals/StatusInfoModal";
import { GetAllInfoApi } from "../../api/GetAllInfoApi";
import { GetCustomersStatusApi } from "../../api/GetCustomersStatusApi";
import AnotherCustomerStatusModal from "../Modals/AnotherCustomerStatusModal";
import { useLocation } from "react-router-dom";
import { useSocket } from "../../providers/SocketProvider";

function Navbar(props) {
  const [changeName, setChangeName] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [data, setData] = useState([]);
  const [customerModal, setCustomerModal] = useState(false);
  const [customerData, setCustomerData] = useState();
  const [enableCustomerAdminData, setEnableCustomerAdminData] = useState();
  const [disableCustomerAdminData, setDisableCustomerAdminData] = useState();
  const [enableCustomerByAnotherData, setEnableCustomerByAnotherData] =
    useState();
  const [disableCustomerByAnotherData, setDisableCustomerByAnotherData] =
    useState();
  const [acceptOrderData, setAcceptOrderData] = useState();
  const [rejectOrderData, setRejectOrderData] = useState();

  const curLocation = useLocation();

  const path = curLocation.pathname;
  const id = path.split("/");

  const socket = useSocket();

  useEffect(() => {
    getAllInfo();
    accessCustomers();
  }, [
    enableCustomerAdminData,
    disableCustomerAdminData,
    enableCustomerByAnotherData,
    disableCustomerByAnotherData,

    acceptOrderData,
    rejectOrderData,
    props.addCustomerData,
  ]);

  useEffect(() => {
    accessCustomers();
  }, [props.addCustomerData]);

  async function getAllInfo() {
    const apiResponse = await GetAllInfoApi(
      id[1],
      localStorage.getItem("userId")
    );

    if (apiResponse.success) {
      setData(apiResponse.data);
    }
  }

  async function accessCustomers() {
    const apiResponse = await GetCustomersStatusApi(
      id[3]
    );
    if (apiResponse.success) {
      setCustomerData(apiResponse.data);
    }
  }

  const enableCustomerAdmin = (data) => {
    setEnableCustomerAdminData(data?.data);
  };

  const disableCustomerAdmin = (data) => {
    setDisableCustomerAdminData(data?.data);
  };

  const enableCustomerByAother = (data) => {
    setEnableCustomerByAnotherData(data?.data);
  };

  const disableCustomerByAother = (data) => {
    setDisableCustomerByAnotherData(data?.data);
  };

  const acceptOrder = (data) => {
    setAcceptOrderData(data?.data);
  };

  const rejectOrder = (data) => {
    setRejectOrderData(data?.data);
  };

  useEffect(() => {
    socket?.on("enable-customer-admin", enableCustomerAdmin);
    socket?.on("disable-customer-admin", disableCustomerAdmin);
    socket?.on("enable-customer-by-another", enableCustomerByAother);
    socket?.on("disable-customer-by-another", disableCustomerByAother);
    socket?.on("acceptOrder", acceptOrder);
    socket?.on("rejectOrder", rejectOrder);

    return () => {
      socket?.off("enable-customer-admin", enableCustomerAdmin);
      socket?.off("disable-customer-admin", disableCustomerAdmin);
      socket?.off("enable-customer-by-another", enableCustomerByAother);
      socket?.off("disable-customer-by-another", disableCustomerByAother);
      socket?.off("acceptOrder", acceptOrder);
      socket?.off("rejectOrder", rejectOrder);
    };
  }, [socket]);

  return (
    <>
      <div className="md:px-4 py-2 border-b">
        <div className="flex justify-between items-center">
          <div className="w-2/5">
            {data?.logoUrl ? (
              <img className="h-12 object-cover" src={data?.logoUrl} />
            ) : (
              <div className="text-2xl">{data?.name}</div>
            )}
          </div>

          <div className="flex">
            <div className="  flex items-center justify-center  text-gray-700">
              <InfoCircleOutlined
                style={{ fontSize: "20px" }}
                onClick={() => setInfoModal(true)}
              />
            </div>
            <div className=" w-10 h-10 rounded-full flex items-center justify-center text-gray-700">
              <TeamOutlined
                style={{ fontSize: "20px" }}
                onClick={() => {
                  accessCustomers();
                  setCustomerModal(true);
                }}
              />
            </div>
            <div className="w-10 h-10 bg-gray-200 rounded-full flex items-center justify-center text-gray-700">
              <UserOutlined
                style={{ fontSize: "20px" }}
                onClick={() => setChangeName(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <ChangeName
        visible={changeName}
        handleCancel={() => setChangeName(false)}
        handleConfirm={() => setChangeName(false)}
      />
      <StatusInfoModal
        visible={infoModal}
        handleCancel={() => setInfoModal(false)}
        handleConfirm={() => setInfoModal(false)}
      />
      <AnotherCustomerStatusModal
        visible={customerModal}
        data={customerData}
        handleCancel={() => setCustomerModal(false)}
      />
    </>
  );
}

export default Navbar;
