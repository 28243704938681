import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../Features/UserSlice";
import authReducer from "../Features/AuthSlice";

export default configureStore({
  reducer: {
    userState: userReducer,
    authState: authReducer,
  },
});
