import { Layout } from "antd";
import React from "react";
import { Outlet } from "react-router-dom";

function InvoiceLayout() {
  return (
    <div className="container">
      <Layout style={{ backgroundColor: "#fff" }}>
        <Outlet />
      </Layout>
    </div>
  );
}

export default InvoiceLayout;
