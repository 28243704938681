import { Badge } from "antd";
import React, { useEffect, useState } from "react";
import { GetOrderItemApi } from "../../api/GetOrderItemApi";
import { STATUS } from "../../Helpers/constants";
import AddOrderItemModal from "../Modals/AddOrderItemModal";
import EditOrderItemModal from "../Modals/EditOrderItemModal";
import CustomerAccessRestrictionModal from "../Modals/CustomerAccessRestrictionModal";
import { currencyFormatter } from "../../Helpers/currencyFormat";
import { useLocation } from "react-router-dom";

function Item(props) {
  const [itemCard, setItemCard] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [editModal, setEditModal] = useState(false);
  const [editItem, setEditItem] = useState();
  const [disabled, setDisabled] = useState(false);
  const [requested, setRequested] = useState(false);
  const [orderRequested, setOrderRequested] = useState(false);
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [orderCancelled, setOrderCancelled] = useState(false);
  const [orderClosed, setOrderClosed] = useState(false);
  const [orderRejected, setOrderRejected] = useState(false);
  const [data, setData] = useState([]);
  const [accessModal, setAccessModal] = useState(false);
  const [message, setMessage] = useState("");
  const [orderNotFound, setOrderNotFound] = useState(false);
  const curLocation = useLocation();
  const path = curLocation.pathname;
  const id = path.split("/");

  useEffect(() => {
    getOrder();
  }, [props, props.confirmRealtimeData, props.getRealtimeData.data, props.getRealtimeData.message]);

  const userName = localStorage.getItem("userName");

  async function getOrder() {
    const apiResponse = await GetOrderItemApi(
      id[3],
      localStorage.getItem("userId")
    );
    if (apiResponse.message === "you are in requested status") {
      setRequested(true);
      setMessage("Wait until someone let you in");
    } else if (apiResponse.message === "Not Acceptable") {
      setDisabled(true);
      setMessage("You don't have access this order");
    } else if (apiResponse.message === "Requested in order") {
      setOrderRequested(true);
      setMessage("Not accepted to add order");
    } else if (apiResponse.message === "your order is cancelled") {
      setOrderCancelled(true);
      setMessage("Your order is cancelled");
    }else if (apiResponse.message === "your order is rejected") {
      setOrderCancelled(true);
      setMessage("Your order is Rejected");
    } else if (apiResponse.success && apiResponse.data?.adminDetail?.orderStatus === STATUS.Completed) {
      setOrderCompleted(true);
      setMessage("Your order is Completed");
    } else if (apiResponse.success && apiResponse.data?.adminDetail?.orderStatus === STATUS.Closed) {
      setOrderClosed(true);
      setMessage("Your order is Closed");
    } else if (apiResponse.message === "Order not found"){
      setOrderNotFound(true);
      setMessage("Order not found");
    } else if (apiResponse.success && apiResponse.data?.adminDetail?.orderStatus === STATUS.Active) {
      setData(apiResponse.data);
      setRequested(false);
      setDisabled(false);
      setOrderRequested(false);
      setOrderCancelled(false);
      setAccessModal(false);
      setOrderNotFound(false);
    }
  }
  function renderDishes() {
    let output = [];

    props.data?.menuItems?.sort((a, b) => {
      return a.position - b.position;
    });

    props.data?.menuItems?.forEach((menuItem) => {
      const selected =
        data?.customerSelectedOrderDetail?.orderDetails?.orderselectedEditDetail?.find(
          (item) =>
            item?.menuItemId === menuItem.id &&
            item.status === STATUS.Selected &&
            item.orderBy === userName
        )
          ? true
          : false;

      const anotherCustomerSelected =
        data?.customerSelectedOrderDetail?.orderDetails?.orderselectedEditDetail?.find(
          (item) =>
            item.menuItemId === menuItem.id &&
            item.status === STATUS.Selected &&
            item.orderBy !== userName
        )
          ? true
          : false;

      const confirmed =
        data?.customerConfirmedOrderDetail?.orderDetails?.orderItemConfirmed.find(
          (item) =>
            item.menuItemId === menuItem.id && item.status === STATUS.Confirmed
        )
          ? true
          : false;

      const accepted =
        data?.customerConfirmedOrderDetail?.orderDetails?.orderItemAccepted.find(
          (item) =>
            item.menuItemId === menuItem.id && item.status === STATUS.Accepted
        )
          ? true
          : false;

      const countSelected = selected
        ? data?.customerSelectedOrderDetail?.orderDetails?.orderselectedEditDetail?.reduce(
            (acc, item) => {
              // Check if the menuItemId matches and the orderBy matches the userName
              if (
                item?.menuItemId === menuItem.id &&
                item.orderBy === userName
              ) {
                return acc + item.quantity; // Add the item's quantity to the accumulator
              }
              return acc; // Return the accumulated total if there's no match
            },
            0
          ) || 0
        : null;

      const countConfirmed =
        confirmed || accepted || anotherCustomerSelected
          ? (data?.customerConfirmedOrderDetail?.orderDetails?.orderItemConfirmed?.reduce(
              (acc, item) => {
                // Check if the menuItemId matches and the orderBy matches the userName
                if (item?.menuItemId === menuItem.id) {
                  return acc + item.quantity; // Add the item's quantity to the accumulator
                }
                return acc; // Return the accumulated total if there's no match
              },
              0
            ) || 0) +
            (data?.customerConfirmedOrderDetail?.orderDetails?.orderItemAccepted?.reduce(
              (acc, item) => {
                // Check if the menuItemId matches and the orderBy matches the userName
                if (item?.menuItemId === menuItem.id) {
                  return acc + item.quantity; // Add the item's quantity to the accumulator
                }
                return acc; // Return the accumulated total if there's no match
              },
              0
            ) || 0) + (data?.customerSelectedOrderDetail?.orderDetails?.orderselectedEditDetail?.reduce(
              (acc, item) => {
                // Check if the menuItemId matches and the orderBy matches the userName
                if (
                  item?.menuItemId === menuItem.id &&
                  item.orderBy !== userName
                ) {
                  return acc + item.quantity; // Add the item's quantity to the accumulator
                }
                return acc; // Return the accumulated total if there's no match
              },
              0
            ) || 0)
          : null;

      const handleClick = () => {
        if (requested || disabled || orderRequested || orderCancelled || orderRejected || orderCompleted || orderClosed || orderNotFound) {
          setAccessModal(true);
        } else if (menuItem.status === STATUS.Available) {
          setItemCard(true);
          setSelectedItem(menuItem);
        }
      };

      output.push(
        <div key={menuItem.id} className="flex pb-4 md:pb-5 items-center ">
          <div
            className="flex-none w-16 md:w-24 lg:w-28 h-16 md:h-24 flex justify-center items-center "
            
          >
            <img
              className="object-cover w-full h-full rounded-lg shadow-md"
              src={menuItem?.imageUrl}
              alt="menu item"
            />
          </div>
          <div
            className="flex-1 cursor-pointer "
            onClick={
              handleClick
            }
          >
            <div className="flex justify-between ">
              <div
                className={
                  menuItem.status === STATUS.OutOfStock
                    ? "w-2/3 text-gray-600 font-semibold ml-4 md:ml-6 text-sm"
                    : "w-2/3 font-semibold ml-4 md:ml-6 text-sm"
                }
              >
                {menuItem.name}
              </div>

              <div
                className={menuItem.status === STATUS.OutOfStock ? "text-gray-600" : ""}
              >
                <div className="text-sm md:text-base">
                  {currencyFormatter(menuItem?.price, props?.currency)}
                </div>
              </div>
            </div>
            <div className="flex justify-between ">
              <div className="text-sm md:text-base text-gray-500 sm:w-2/3 text-left lg:w-auto lg:flex-wrap mr-[84px] md:mr-24 ml-4 md:ml-6">
                <div className="line-clamp-2">{menuItem.description}</div>
              </div>
              {menuItem.status === STATUS.OutOfStock ? (
                <div className="text-sm text-gray-500 ">Out of stock</div>
              ) : (
                <div className="flex">
                  {accepted || confirmed || anotherCustomerSelected ? (
                    <Badge
                      className="mr-1"
                      size="small"
                      color={"rgb(120 113 108)"}
                      count={countConfirmed}
                    />
                  ) : (
                    ""
                  )}
                  {selected ? (
                    <Badge
                      className="mr-1"
                      size="small"
                      color={"rgb(22 163 74)"}
                      count={countSelected}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
    return output;
  }

  return (
    <>
      <div>{renderDishes()}</div>
      <AddOrderItemModal
        visible={itemCard}
        data={selectedItem}
        handleCancel={() => setItemCard(false)}
        handleOk={() => setItemCard(false)}
        handleData={(data) => props.handleData(data)}
      />
      <EditOrderItemModal
        visible={editModal}
        data={editItem}
        handleCancel={() => setEditModal(false)}
        handleOk={() => {
          setEditModal(false);
        }}
      />
      <CustomerAccessRestrictionModal
        visible={accessModal}
        handleCancel={() => setAccessModal(false)}
        data={message}
      />
    </>
  );
}
export default Item;
