import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { currencyFormatter } from "../../Helpers/currencyFormat";

function AcceptOrderItemsInfoModal(props) {
  const [orderData, setOrderData] = useState([]);

  useEffect(() => {
    setOrderData(props.data);
  }, [props.data]);

  return (
    <div>
      <Modal
        open={props.visible}
        footer={null}
        onCancel={() => props.handleCancel()}
        onOk={() => props.handleConfirm()}
        title="Order Details"
        destroyOnClose={true}
      >
        <div className="text-base font-medium flex items-center justify-between mb-2">
          <span>{orderData?.menuName} </span>
          <span>
            {currencyFormatter(orderData?.price, props?.currency)}
          </span>
        </div>
        <div>
          {orderData?.orderBy?.map((value, index) => (
            <div key={index}>
              <span className="text-base font-medium">{value.name}</span>
              {value.modifier_order_items?.map((item, itemIndex) => (
                <div key={itemIndex}>
                  <div className="text-xs leading-5 text-gray-500 md:flex md:ml-5 ">
                    <span className="font-medium">{item.name} : </span>
                    <span className="ml-12 md:ml-1 leading-5 flex flex-wrap">
                      {item.modifier.map((modifier, modifierIndex) => (
                        <span key={modifierIndex} className="mr-2">
                          <span className="text-gray-600">
                            {modifier.name} :{" "}
                            ({currencyFormatter(modifier.price, props?.currency)})
                          </span>
                          {modifierIndex !== item.modifier.length - 1 && ", "}
                        </span>
                      ))}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

export default AcceptOrderItemsInfoModal;
