import React, { useEffect, useState } from "react";
import Tic from "../assets/images/Success-Tick.png";
import { GetOrderItemApi } from "../api/GetOrderItemApi";
import { useLocation, useNavigate } from "react-router-dom";
import { STATUS } from "../Helpers/constants";

function Gratitude() {
  const curLocation = useLocation();
  const path = curLocation.pathname;
  const id = path.split("/");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getOrder();
  }, []);

  async function getOrder() {
    const apiResponse = await GetOrderItemApi(
      id[3],
      localStorage.getItem("userId")
    );
    if (apiResponse.message === "you are in requested status") {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    } else if (apiResponse.message === "Not Acceptable") {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    } else if (apiResponse.message === "Requested in order") {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    } else if (apiResponse.message === "your order is cancelled") {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    } else if (apiResponse.message === "your order is rejected") {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    } else if (apiResponse.success && apiResponse.data?.adminDetail?.orderStatus === STATUS.Completed) {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/invoice`);
    } else if (apiResponse.success && apiResponse.data?.adminDetail?.orderStatus === STATUS.Closed) {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/gratitude`);
    }else if (apiResponse.success && apiResponse.data?.adminDetail?.orderStatus === STATUS.Active) {
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    }
    setLoading(false);
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mt-40 md:mt-52">
        {!loading && ( 
          <div className="mx-auto relative p-4 rounded-lg text-center bg-white shadow-lg">
            <img
              src={Tic}
              className="absolute -top-8 right-0 h-16 md:h-12 object-cover"
              alt="Logo"
            />
            <p className="text-lg mb-4 text-gray-500">
              Thank you for dining with{" "}
              <span className="text-orange-500 font-bold">Dinegrid</span>. We
              hope you enjoyed your meal!
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Gratitude;
