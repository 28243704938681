import { Form, Input } from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateUserApi from "../api/User/CreateUserApi";
import logo from "../assets/images/Dinegrid.png";
import { useSocket } from "../providers/SocketProvider";

function UserInfo() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const curLocation = useLocation();

  const socket = useSocket();

  const path = curLocation.pathname;
  const id = path.split("/");

  async function onFinish(values) {
    const apiResponse = await CreateUserApi(
      id[3],
      values.name
    );
    if (apiResponse.success) {
      localStorage.setItem("userId", apiResponse.data?.id);
      localStorage.setItem("userName", apiResponse.data?.name);
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    }
  }

  const sendAddCustomer = (values) => {
    const addCustomer = {
      orderId: id[3],
      name: values.name,
    };

    socket?.emit("add-customer-name", addCustomer);
  };

  useEffect(() => {
    socket?.on("add-customer-name", addCustomerName);

    return () => {
      socket?.off("add-customer-name", addCustomerName);
    };
  }, [socket]);

  const addCustomerName = (data) => {
    if (data.success) {
      window.localStorage.setItem("userId", data?.data.id);
      window.localStorage.setItem("userName", data?.data.name);
      navigate(`/${id[1]}/${id[2]}/${id[3]}/menu`);
    }
  };

  return (
    <div className="container">
      <div className=" flex justify-center mt-8">
        <img className="w-64 rounded-md" src={logo} />
      </div>

      <Form
        name="userInfo"
        onFinish={sendAddCustomer}
        autoComplete="off"
        requiredMark={false}
        form={form}
        className="mt-36 md:mt-40"
      >
        <div className="flex items-center p-4">
          <div className="w-full max-w-lg mx-auto">
            <div>
              <div className="mb-1 text-base font-medium">Please enter your name</div>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your name!",
                  },
                ]}
              >
                <Input
                  placeholder="Type your name here.."
                  size="large"
                  type="name"
                  className="w-full"
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="w-full p-4 text-center">
          <button
            className="bg-lime-700 hover:bg-lime-800 text-white font-semibold p-2 rounded-lg w-full max-w-lg mx-auto shadow-lg"
            type="submit"
          >
            Check Menu
          </button>
        </div>
      </Form>
    </div>
  );
}
export default UserInfo;
