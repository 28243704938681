import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import React from "react";

function CustomerAccessRestrictionModal(props) {
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.handleCancel()}
      footer={null}
      closable={false}
    >
        <div className="flex items-baseline text-gray-500 gap-2"> 
            <ExclamationCircleOutlined className="text-lg text-gray-400"/>
            <div className="font-medium text-base">{props.data}</div>
        </div>
      
      
    </Modal>
  );
}

export default CustomerAccessRestrictionModal;
