import { Form, Input, Modal } from "antd";
import React from "react";
import EditUserNameApi from "../../api/User/EditUserNameApi";

function ChangeName(props) {
  const [form] = Form.useForm();

  async function onFinish(values) {
    const apiResponse = await EditUserNameApi(
      values.name,
      localStorage.getItem("userId")
    );
    if (apiResponse.success) {
      localStorage.setItem("userId", apiResponse.data?.id);
      localStorage.setItem("userName", apiResponse.data?.name);
      props.handleConfirm();
    }
  }

  return (
    <Modal
      open={props.visible}
      onCancel={() => props.handleCancel()}
      onOk={() => props.handleConfirm()}
      footer={null}
      title="Change your name"
    >
      <Form
        name="username"
        onFinish={onFinish}
        autoComplete="off"
        requiredMark={false}
        form={form}
        initialValues={{ name: localStorage.getItem("userName") }}
      >
        <div className="mt-6">
          <Form.Item name="name">
            <Input
              placeholder="Name"
              size="large"
              type="name"
              className="w-full mb-3"
            />
          </Form.Item>

          <div
            className="bg-lime-700 text-white p-2 text-center rounded-lg hover:shadow mb-2"
            onClick={() => form.submit()}
          >
            Confirm
          </div>
          <div
            className="bg-white text-blue-500 pt-2 text-center"
            onClick={props.handleCancel}
          >
            Cancel
          </div>
        </div>
      </Form>
    </Modal>
  );
}
export default ChangeName;
