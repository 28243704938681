import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Modal, Radio, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useSocket } from "../../providers/SocketProvider";
import { currencyFormatter } from "../../Helpers/currencyFormat";
import { GetAllInfoApi } from "../../api/GetAllInfoApi";
import { useLocation } from "react-router-dom";

function AddOrderItemModal(props) {
  const [form] = Form.useForm();
  const [qty, setQty] = useState(1);
  const [submit, setSubmit] = useState(false);
  const [note, setNote] = useState("");
  const [business, setBusiness] = useState([]);
  const [modifierGroups, setModifierGroups] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [addOrderModifier, setAddOrderModifier] = useState([]);
  const [formInitialValues, setFormInitialValues] = useState({});

  useEffect(() => {
    setModifierGroups(props.data?.modifierGroups || []);
  }, [props.data]);

  modifierGroups.sort((a, b) => a.position - b.position);

  useEffect(() => {
    const initialSelectedVariants = modifierGroups
      .flatMap((group) =>
        group.modifiers.map((modifier) => ({
          ...modifier,
          groupId: group.id,
        }))
      )
      .filter((modifier) => modifier.isPreSelected);

    setSelectedVariants(initialSelectedVariants);
  }, [modifierGroups]);

  const socket = useSocket();

  useEffect(() => {
    getAllInfo();
  }, []);

  useEffect(() => {
    const selectedModifiers = selectedVariants.map((modifier) => ({
      modifierGroupId: modifier.groupId,
      modifierId: modifier.id,
    }));
    setAddOrderModifier(selectedModifiers);
  }, [selectedVariants]);

  async function getAllInfo() {
    const apiResponse = await GetAllInfoApi(
      id[1],
      localStorage.getItem("userId")
    );

    if (apiResponse.success) {
      setBusiness(apiResponse.data);
    }
  }

  const curLocation = useLocation();

  const path = curLocation.pathname;
  const id = path.split("/");

  const addOrderDetail = {
    customerId: localStorage.getItem("userId"),
    menuItemId: props.data?.id,
    note: note,
    quantity: qty,
    orderId: id[3],
    modifiers: addOrderModifier,
  };

  const send = () => {
    const isRequiredItemsNotSelected = modifierGroups.some(
      (group) =>
        group.isRequired && countSelectedVariants(group.modifiers) === 0
    );
    if (isRequiredItemsNotSelected) {
      const firstGroupWithMissingRequiredItem = modifierGroups.find(
        (group) =>
          group.isRequired && countSelectedVariants(group.modifiers) === 0
      );
      if (firstGroupWithMissingRequiredItem) {
        const sectionId = firstGroupWithMissingRequiredItem.id;
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      socket?.emit("addOrders", addOrderDetail);
      props.handleCancel();
    }
  };

  const addOrders = (data) => {
    props.handleData(data?.data);
  };

  useEffect(() => {
    socket?.on("addOrders", addOrders);

    return () => {
      socket?.off("addOrders", addOrders);
    };
  }, [socket]);

  const handleMinus = () => {
    if (qty === 1) return;
    setQty(qty - 1);
  };

  const handlePlus = () => {
    setQty(qty + 1);
  };

  const handlePreselectChange = (groupIndex, modifierIndex) => {
    const updatedModifierGroups = modifierGroups.map((group, i) => {
      if (i === groupIndex) {
        return {
          ...group,
          modifiers: group.modifiers.map((modifier, j) => ({
            ...modifier,
            isPreSelected: j === modifierIndex,
          })),
        };
      }
      return group;
    });

    const newlySelectedModifiers = updatedModifierGroups
      .flatMap((group) =>
        group.modifiers.map((modifier) => ({ ...modifier, groupId: group.id }))
      )
      .filter((modifier) => modifier.isPreSelected);

    setSelectedVariants(newlySelectedModifiers);
    setModifierGroups(updatedModifierGroups);
  };

  const handleCheckboxChange = (groupIndex, modifierIndex, isChecked) => {
    const updatedModifierGroups = modifierGroups.map((group, i) => ({
      ...group,
      modifiers: group.modifiers.map((modifier, j) => ({
        ...modifier,
        isPreSelected:
          i === groupIndex && j === modifierIndex
            ? isChecked
            : modifier.isPreSelected,
      })),
    }));

    const newlySelectedModifiers = updatedModifierGroups
      .flatMap((group) =>
        group.modifiers.map((modifier) => ({ ...modifier, groupId: group.id }))
      )
      .filter((modifier) => modifier.isPreSelected);

    setSelectedVariants(newlySelectedModifiers);
    setModifierGroups(updatedModifierGroups);
  };
  const countSelectedVariants = (modifiers) => {
    return modifiers.filter((modifier) => modifier.isPreSelected).length;
  };

  const calculateTotalPrice = () => {
    let totalPrice = (props.data?.price || 0) * qty;
    selectedVariants.forEach((variant) => {
      totalPrice += (variant.price || 0) * qty;
    });
    return totalPrice;
  };

  const renderModifierOptions = (modifierGroup) => {
    if (!modifierGroup || !modifierGroup.modifiers) {
      return null;
    }
    const { minimum, maximum, isRequired } = modifierGroup;

    if (
      isRequired &&
      minimum !== undefined &&
      maximum !== undefined &&
      minimum === maximum
    ) {
      return <p className="text-sm text-gray-400">Choose {maximum}</p>;
    } else if (
      isRequired &&
      minimum !== undefined &&
      maximum !== undefined &&
      maximum !== null
    ) {
      return (
        <p className="text-sm text-gray-400">
          Choose between {minimum} and {maximum}
        </p>
      );
    } else if (minimum !== undefined && maximum === null) {
      return <p className="text-sm text-gray-400">Choose any</p>;
    } else if (
      !isRequired &&
      minimum !== undefined &&
      maximum !== undefined &&
      maximum !== null
    ) {
      return <p className="text-sm text-gray-400">Choose upto {maximum}</p>;
    }
  };

  const renderModifierGroup = (modifierGroup, groupIndex) => {
    if (!modifierGroup || !modifierGroup.modifiers) {
      return null;
    }
    const { minimum, maximum, isRequired, modifiers, name, id } = modifierGroup;
    const isAtLeastOneSelected =
      isRequired && countSelectedVariants(modifiers) > 0;

    return (
      <div key={id} id={id}>
        <div className="flex items-center justify-between mt-5">
          <div>
            <h1 className="text-base md:text-lg font-bold">{name}</h1>
            <div className="mb-2">{renderModifierOptions(modifierGroup)}</div>
          </div>
          {isRequired && (
            <Tag color={isAtLeastOneSelected ? "green" : "red"}>
              {isAtLeastOneSelected ? "Required" : "Required"}
            </Tag>
          )}
        </div>
        {modifiers.map((modifier, index) => (
          <div key={modifier.id}>
            <div className="flex items-center justify-between">
              <div>
                <strong>{modifier.name}</strong>
                <p className="text-sm text-gray-400 p-0 m-0">
                  + {currencyFormatter(modifier.price, business?.currency)}
                </p>
              </div>
              {isRequired && minimum && maximum === 1 ? (
                <Radio.Group
                  value={modifier.isPreSelected}
                  onChange={() => handlePreselectChange(groupIndex, index)}
                >
                  <Radio value={true}></Radio>
                </Radio.Group>
              ) : (
                <Checkbox
                  checked={modifier.isPreSelected}
                  onChange={(e) =>
                    handleCheckboxChange(groupIndex, index, e.target.checked)
                  }
                  disabled={
                    maximum &&
                    countSelectedVariants(modifiers) >= maximum &&
                    !modifier.isPreSelected
                      ? true
                      : false
                  }
                ></Checkbox>
              )}
            </div>
            <hr className="mt-[5px] mb-[5px]" />
          </div>
        ))}
      </div>
    );
  };

  function handleCancel() {
    props.handleCancel();
    setQty(1);
    setModifierGroups(props.data?.modifierGroups || []);
  }
  
  useEffect(() => {
    const initialValues = {};
    modifierGroups.forEach((group) => {
      initialValues[`modifier-${group.id}`] = undefined;
    });
    setFormInitialValues(initialValues);
  }, [form, modifierGroups]);

  
  return (
    <>
      <Modal
        open={props.visible}
        footer={null}
        onCancel={handleCancel}
        onOk={() => props.handleConfirm()}
        destroyOnClose={true}
      >
        <Form
          form={form}
          name="addOrderItem"
          onFinish={send}
          autoComplete="off"
          requiredMark={false}
          initialValues={formInitialValues}
        >
          {/* Image and details row */}
          <div className="flex flex-row items-start">
            {/* Image container */}
            <div className="flex-none w-24 h-24 md:w-28 md:h-28 lg:w-32 lg:h-28 overflow-hidden">
              <img
                className="object-cover w-full h-full rounded-md shadow-md"
                src={props.data?.imageUrl}
                alt="menu item"
              />
            </div>
            {/* Details container */}
            <div className="flex-grow ml-4">
              <div className="flex flex-col justify-between ">
                <div className="font-bold text-base md:text-xl">
                  {props.data?.name}
                </div>
                <div className="text-sm md:text-sm line-clamp-2 h-[44px] md:h-[45px]">
                  {props.data?.description}
                </div>
                <div className="font-bold text-base md:text-xl">
                  {currencyFormatter(calculateTotalPrice(), business?.currency)}
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* Variant section */}
            <div>
              {modifierGroups.map((modifierGroup, index) => (
                <Form.Item
                  className="mt-1"
                  key={modifierGroup.id}
                  name={`modifier-${modifierGroup.id}`}
                >
                  {renderModifierGroup(modifierGroup, index)}
                </Form.Item>
              ))}
            </div>
          </div>

          <div>
            {/* Add a note section */}
            <div className="mb-1 mt-2 font-semibold">Special Instruction</div>
            <Input.TextArea
              rows={2}
              placeholder="Add a Note (e.g., no onions, extra spicy)"
              onChange={(e) => {
                setNote(e.target.value);
              }}
            />

            {/* Quantity selectors */}
            <div className="mt-5 mb-5 py-4">
              <div className="flex items-center justify-center bg-gray-200 font-bold rounded-2xl md:rounded-3xl w-28 md:w-32 py-1 mx-auto shadow">
                <MinusOutlined
                  className="md:text-lg font-[1000]"
                  onClick={handleMinus}
                />
                <div className="px-6 md:mt-[1px] text-base md:text-lg">
                  {qty}
                </div>
                <PlusOutlined
                  className="md:text-lg font-[1000]"
                  onClick={handlePlus}
                />
              </div>
            </div>

            {/* Add button */}
            <div className="grid justify-items-stretch">
              <Button
                style={{
                  backgroundColor: "#4d7c0f",
                  transition: "background-color 0.3s",
                }}
                className=" bg-lime-600 mb-2 text-white font-semibold shadow-md"
                type="primary"
                htmlType='submit'
              >
                Add {qty} to Cart
              </Button>
            </div>

            {/* Cancel button */}
            <div
              className="bg-white text-red-500 hover:text-blue-500 p-2 shadow rounded-lg text-center cursor-pointer font-semibold mb-2"
              onClick={handleCancel}
              type="button"
            >
              Cancel
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
}
export default AddOrderItemModal;
