import React, { useEffect } from "react";
import { useSocket } from "../providers/SocketProvider";

function Initial() {
  const socket = useSocket();

  const queryParameters = new URLSearchParams(window.location.search);
  const businessId = queryParameters.get("businessId");
  const qrId = queryParameters.get("qrId");

  useEffect(() => {
    if (qrId) {
      sendCreateOrder();
    }
  }, []);

  const sendCreateOrder = () => {
    socket?.emit("createOrder", createOrderDetail);
  };

  const createOrderDetail = {
    qrId: qrId,
  };

  return <div>Initial</div>;
}

export default Initial;
